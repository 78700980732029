@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("http://fonts.cdnfonts.com/css/red-hat-display");
@import url("http://fonts.cdnfonts.com/css/courier-prime");
@import url("https://fonts.cdnfonts.com/css/barlow");

body {
  background: whitesmoke;
  font-family: "Barlow", sans-serif;
}

@media (min-width: 1024px) {
  .mainContainer {
    width: 1000px;
  }
}
